<template>
  <div class="auth-wrapper auth-v2 bg">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0 auth-bg-left">
          <v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex>
                <div class="left-text-container">
                  <div class="pa-16 pe-0 pt-8 pb-8 left-text-title">
                    The payment platform<br />you were waiting for.
                  </div>
                  <div class="pa-16 pe-0 pt-0 left-text-subtitle">
                    Our robust and flexible technology makes payments so seamless you don’t even need to think about
                    them
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>
        <v-col lg="4" class="align-center auth-bg-right pa-5 pb-0">
          <v-container>
            <div class="brand-logo">
              <img :src="appLogo" alt="Key2Pay"/>
            </div>
          </v-container>
          <v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex>
                <v-card flat light>
                  <v-card-title> Welcome back! 👋🏻 </v-card-title>
                  <v-card-subtitle> Login in to your account below. </v-card-subtitle>
                  <v-card-text v-if="errorMessages.length > 0" class="mb-0">
                    <v-alert height="56" type="error" outlined class="mb-2">
                      <small v-for="(err, idx) in errorMessages" :key="idx" class="d-block">
                        <strong>{{ err.message }}</strong>
                      </small>
                    </v-alert>
                  </v-card-text>

                  <!-- login form -->
                  <v-card-text>
                    <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                      <v-text-field
                        v-model="email"
                        min-height="56"
                        outlined
                        label="Username"
                        placeholder="Username"
                        :error-messages="errorMessages.email"
                        :rules="[validators.required, validators.emailValidator]"
                        hide-details="auto"
                        class="mb-5"
                      ></v-text-field>

                      <v-text-field
                        v-model="password"
                        min-height="56"
                        outlined
                        slot=""
                        :type="isPasswordVisible ? 'text' : 'password'"
                        label="Password"
                        :error-messages="errorMessages.password"
                        placeholder="Password"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-5"
                        @click:append="isPasswordVisible = !isPasswordVisible"                        
                      ></v-text-field>

                      <div class="mb-5 pl-0">
                        <VueRecaptcha
                          :sitekey="siteKey"
                          :load-recaptcha-script="true"
                          @verify="handleReCaptchaSucess"
                          @error="handleReCaptchaError"
                          @expired="handleExpired"
                        ></VueRecaptcha>
                      </div>

                      <v-btn
                        min-height="56"
                        block
                        color="primary"
                        type="submit"
                        :loading="loading"
                        :disabled="loading || reCaptchaToken == null"
                      >
                        Log in
                      </v-btn>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center align-center">
                    <!-- forget link -->
                    <router-link
                      :to="{ name: 'auth-forgot-password' }"
                      class="d-flex align-center text-sm forgot-password"
                    >
                      <span>Forgot Password?</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref, computed } from '@vue/composition-api'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITEKEY)
    const reCaptchaToken = ref(null)
    const vueRecaptchaCtl = ref(null)
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const loading = ref(false)
    const isPasswordVisible = ref(false)

    const email = ref(null)
    const password = ref(null)
    const errorMessages = ref([])

    const handleReCaptchaError = () => {
      reCaptchaToken.value = null
    }

    const handleExpired = () => {
      reCaptchaToken.value = null
    }

    const handleReCaptchaSucess = response => {
      reCaptchaToken.value = response
    }

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      errorMessages.value = ref([])

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      axios
        .post('/userAccess/authentication', {
          username: email.value,
          password: password.value,
          token: reCaptchaToken.value,
        })
        .then(response => {
          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', response.data.accessToken)

          return response
        })
        .then(() => {
          axios.get('/userAccess/authorization').then(response => {
            const user = response.data
            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.ability

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // On success redirect to home
            router.push('/')
          })
        })
        .catch(error => {
          errorMessages.value = error.response.data.errors
          vueRecaptchaCtl.value.reset()
          reCaptchaToken.value = null
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      handleFormSubmit,
      handleReCaptchaError,
      handleReCaptchaSucess,
      handleExpired,
      siteKey,
      reCaptchaToken,
      vueRecaptchaCtl,
      isPasswordVisible,
      loading,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/auth.scss';
</style>
